<template>
  <div>
  </div>
</template>

<script>
import { loginWechatUser } from '@/api/signin.js'
import Cookie from 'js-cookie'
export default {
  data() {
    return {
      token: this.$route.query.token || '',
      uuid: this.$route.query.uuid || '',
      app_code: this.$route.query.app_code || '',
      wxopenid: this.$route.query.wxopenid || ''
    }
  },
  created() {
    if (this.wxopenid) {
      Cookie.set('wxopenid', this.wxopenid, { expires: 365 })
    }
    this.wxLogin(this.token, this.uuid, this.app_code)
  },
  methods: {
    wxLogin(access_token, uuid, app_code) {
      const info = {
        app_code: app_code,
        access_token: access_token,
        uuid: uuid
      }
      loginWechatUser(info).then((res) => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const username = result.username
          console.log('登录成功 ', username)
        }
        this.$router.go(-1)
      }).catch((err) => {
        this.$router.go(-1)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
